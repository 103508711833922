import { useState } from 'react';
import Adsbox from './components/Adsbox';
import Menu from './components/Menu';
import Navbar from './components/Navbar';
import Profile from './components/Profile';
import Tabbar from './components/Tabs';
import { useLocation } from 'react-router-dom';
import "./display.scss";

const Display = () => {
    const location = useLocation();
    // Safely access location.state and provide default fallback
    const { deviceName = "Unknown Device", address = {} } = location.state || {};
    const [activeTopTab, setActiveTopTab] = useState(1);

    console.log(`deviceName: ${deviceName}, address:`, address);

    return (
        <div id="cssportals-grid">
            <div id="profile-box"><Profile /></div>
            <div id="adsbox"><Adsbox TabValue={activeTopTab} deviceName={deviceName} address={address} /></div>
            <div id="navbar-box"><Navbar /></div>
            <div id="tabs"><Tabbar activeTopTab={activeTopTab} setActiveTopTab={setActiveTopTab} /></div>
            <div id="menubar"><Menu /></div>
        </div>
    );
}

export default Display;
