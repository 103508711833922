// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parent {
  width: 98vw;
  display: grid;
  color: aliceblue;
  grid-template-columns: 0.1fr 0.5fr 0.5fr 0.5fr;
  grid-template-rows: 0.1fr 0.4fr 0.6fr 0.2fr 0fr 0fr;
  gap: 10px; }

/* Media query for screens with height between 600px and 800px */
.div1 {
  grid-column: span 5 / span 5;
  display: flex;
  align-items: center; }

.div2 {
  grid-column-start: 6;
  grid-row-start: 1; }

.div3 {
  grid-row: span 4 / span 4;
  grid-row-start: 2; }

.div4 {
  grid-column: span 3 / span 3;
  grid-row: span 3 / span 3;
  grid-row-start: 2; }

.div5 {
  grid-column-start: 2;
  grid-row-start: 5; }

.div6 {
  grid-column-start: 3;
  grid-row-start: 5; }

.div7 {
  grid-column-start: 4;
  grid-row-start: 5; }

.div8 {
  grid-column: span 2 / span 2;
  grid-row: span 2 / span 2;
  grid-column-start: 5;
  grid-row-start: 2; }

.div9 {
  grid-column-start: 5;
  grid-row-start: 4; }

.div10 {
  grid-column-start: 6;
  grid-row-start: 4; }

.div11 {
  grid-column-start: 5;
  grid-row-start: 5;
  grid-column-end: 7; }

.div12 {
  grid-column-start: 6;
  grid-row-start: 5; }
`, "",{"version":3,"sources":["webpack://./src/layout.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,aAAa;EACb,gBAAgB;EAChB,8CAA8C;EAC9C,mDAAmD;EACnD,SAAS,EAAA;;AAGb,gEAAA;AAGA;EACI,4BAA4B;EAC5B,aAAa;EACb,mBAAmB,EAAA;;AAGvB;EACI,oBAAoB;EACpB,iBAAiB,EAAA;;AAGrB;EACI,yBAAyB;EACzB,iBAAiB,EAAA;;AAGrB;EACI,4BAA4B;EAC5B,yBAAyB;EACzB,iBAAiB,EAAA;;AAGrB;EACI,oBAAoB;EACpB,iBAAiB,EAAA;;AAGrB;EACI,oBAAoB;EACpB,iBAAiB,EAAA;;AAGrB;EACI,oBAAoB;EACpB,iBAAiB,EAAA;;AAGrB;EACI,4BAA4B;EAC5B,yBAAyB;EACzB,oBAAoB;EACpB,iBAAiB,EAAA;;AAIrB;EACI,oBAAoB;EACpB,iBAAiB,EAAA;;AAGrB;EACI,oBAAoB;EACpB,iBAAiB,EAAA;;AAGrB;EACI,oBAAoB;EACpB,iBAAiB;EACjB,kBAAkB,EAAA;;AAGtB;EACI,oBAAoB;EACpB,iBAAiB,EAAA","sourcesContent":[".parent {\n    width: 98vw;\n    display: grid;\n    color: aliceblue;\n    grid-template-columns: 0.1fr 0.5fr 0.5fr 0.5fr;\n    grid-template-rows: 0.1fr 0.4fr 0.6fr 0.2fr 0fr 0fr;\n    gap: 10px;\n}\n\n/* Media query for screens with height between 600px and 800px */\n\n\n.div1 {\n    grid-column: span 5 / span 5;\n    display: flex;\n    align-items: center;\n}\n\n.div2 {\n    grid-column-start: 6;\n    grid-row-start: 1;\n}\n\n.div3 {\n    grid-row: span 4 / span 4;\n    grid-row-start: 2;\n}\n\n.div4 {\n    grid-column: span 3 / span 3;\n    grid-row: span 3 / span 3;\n    grid-row-start: 2;\n}\n\n.div5 {\n    grid-column-start: 2;\n    grid-row-start: 5;\n}\n\n.div6 {\n    grid-column-start: 3;\n    grid-row-start: 5;\n}\n\n.div7 {\n    grid-column-start: 4;\n    grid-row-start: 5;\n}\n\n.div8 {\n    grid-column: span 2 / span 2;\n    grid-row: span 2 / span 2;\n    grid-column-start: 5;\n    grid-row-start: 2;\n    // grid-row-end: 3;\n}\n\n.div9 {\n    grid-column-start: 5;\n    grid-row-start: 4;\n}\n\n.div10 {\n    grid-column-start: 6;\n    grid-row-start: 4;\n}\n\n.div11 {\n    grid-column-start: 5;\n    grid-row-start: 5;\n    grid-column-end: 7;\n}\n\n.div12 {\n    grid-column-start: 6;\n    grid-row-start: 5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
