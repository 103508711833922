import { GoogleMap, MarkerF, Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import React, { useState, useEffect, useRef } from 'react';
import { getDatabase, ref, onValue } from "firebase/database"; // Firebase Realtime Database imports
import app from "../config/firebasecloud"; // Firebase config file
import "./map.scss";

const containerStyle = {
    width: '100%',
    height: '100%',
};

const locations = {
    Hyderabad: { lat: 17.385044, lng: 78.486671 },
    Bengaluru: { lat: 12.9715987, lng: 77.594566 },
};

const darkModeStyle = [
    { elementType: 'geometry', stylers: [{ color: '#212121' }] },
    { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
    { elementType: 'labels.text.fill', stylers: [{ color: '#757575' }] },
    { elementType: 'labels.text.stroke', stylers: [{ color: '#212121' }] },
    { featureType: 'administrative', elementType: 'geometry', stylers: [{ color: '#757575' }] },
    { featureType: 'water', elementType: 'geometry', stylers: [{ color: '#000000' }] },
];

function Map({ selectedLocation, setDeviceName ,setDevicelat,setDevicelng }) { // Accept setDeviceName as a prop
    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDawx-PwOvSV24Rkk4WPvvwe79gDDmWIyI",
        libraries: ['places'],
    });

    const [map, setMap] = useState(null);
    const [deviceLocations, setDeviceLocations] = useState([]); // State to hold latest device locations
    const [autocomplete, setAutocomplete] = useState(null);
    const [selectedPosition, setSelectedPosition] = useState(null);
    const autocompleteRef = useRef(null);

    const center = locations[selectedLocation] || locations['Hyderabad'];

    const deviceMarker = isLoaded && window.google ? {
        url: '/device-marker.png',
        scaledSize: new window.google.maps.Size(40, 40), // Custom marker size
    } : null;

    const onLoad = React.useCallback((map) => setMap(map), []);
    const onUnmount = React.useCallback(() => setMap(null), []);

    const onAutocompleteLoad = (autocomplete) => setAutocomplete(autocomplete);

    const onPlaceChanged = () => {
        if (autocomplete) {
            const place = autocomplete.getPlace();
            const location = place.geometry?.location;
            if (location) {
                const newPosition = {
                    lat: location.lat(),
                    lng: location.lng(),
                };
                setSelectedPosition(newPosition);
                map.panTo(newPosition);
                map.setZoom(14);
            }
        }
    };

    // Helper function to get latest location for each device
    const getLatestDeviceLocations = (data) => {
        const latestLocations = {};

        Object.keys(data).forEach(deviceKey => {
            const deviceEntries = Object.values(data[deviceKey]);

            // Find the most recent entry for each device based on timestamp
            deviceEntries.forEach((record) => {
                if (record.location) {
                    const timestamp = new Date(record.timestamp).getTime(); // Convert timestamp to epoch for comparison
                    if (!latestLocations[deviceKey] || timestamp > latestLocations[deviceKey].timestamp) {
                        latestLocations[deviceKey] = {
                            lat: record.location.latitude,
                            lng: record.location.longitude,
                            timestamp: timestamp,
                            deviceName: record.device_name,
                            displayTimestamp: record.timestamp, // Store original display timestamp
                        };
                    }
                }
            });
        });

        return Object.values(latestLocations); // Return as an array of latest device records
    };

    // Fetch device locations from Firebase Realtime Database
    useEffect(() => {
        const db = getDatabase(app);
        const devicesRef = ref(db, 'devices/'); // Reference to the "devices" node

        const unsubscribe = onValue(devicesRef, (snapshot) => {
            const data = snapshot.val();
            if (data) {
                const latestLocations = getLatestDeviceLocations(data);
                setDeviceLocations(latestLocations); // Set the latest locations for each device
            }
        });

        return () => unsubscribe(); // Unsubscribe from the listener when component unmounts
    }, []);

    useEffect(() => {
        if (map && selectedLocation) {
            const newCenter = locations[selectedLocation];
            map.panTo(newCenter);
            map.setZoom(12);
        }
    }, [map, selectedLocation]);

    const handleMarkerClick = (marker) => {
        setDeviceName(marker.deviceName); // Call setDeviceName when marker is clicked
        setDevicelat(marker.lat);
        setDevicelng(marker.lng);

    };

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading...</div>;

    return (
        <div style={{ position: 'relative', height: '100%' }}>
            {/* Search Bar */}
            <Autocomplete
                onLoad={onAutocompleteLoad}
                onPlaceChanged={onPlaceChanged}
            >
                <input
                    type="text"
                    placeholder="Search for a place"
                    style={{
                        boxSizing: 'border-box',
                        border: '1px solid transparent',
                        width: '240px',
                        height: '32px',
                        padding: '0 12px',
                        borderRadius: '3px',
                        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                        fontSize: '14px',
                        position: 'absolute',
                        top: '10px',
                        left: '15%',
                        marginLeft: '-120px',
                        zIndex: 10,
                    }}
                    ref={autocompleteRef}
                />
            </Autocomplete>

            {/* Google Map */}
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={11}
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={{
                    streetViewControl: false,
                    mapTypeControl: false,
                    styles: darkModeStyle,
                }}
            >
                {/* Default Marker for Selected Location */}
                {deviceMarker && <MarkerF position={center} />}

                {/* Marker for Searched Location */}
                {selectedPosition && <MarkerF position={selectedPosition} icon={deviceMarker} />}

                {/* Device Markers for Latest Locations */}
                {deviceLocations.map((marker, index) => (
                    <MarkerF
                        key={index}
                        position={{ lat: marker.lat, lng: marker.lng }} // Use lat and lng from the latest record
                        icon={deviceMarker}
                        onClick={() => handleMarkerClick(marker)} // Add onClick handler for marker
                    />
                ))}
            </GoogleMap>
        </div>
    );
}

export default React.memo(Map);
