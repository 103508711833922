// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ads {
  width: 310px;
  height: 161px;
  margin: auto;
  border-radius: 14px;
  background: linear-gradient(180deg, rgba(21, 20, 26, 0.9) 0%, #2E2D33 100%); }
  .ads .running {
    color: #E8DCD0;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    padding: 16px 6px 6px 10px;
    line-height: normal; }
  .ads .number {
    padding: 0px 6px;
    color: #E8DCD0;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; }
  .ads .line {
    stroke-width: 5px;
    padding: 2px 12px;
    border-radius: 6px;
    stroke: #E8DCD0; }
  .ads .share {
    color: #E8DCD0;
    padding: 2px 12px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; }
  .ads .brand {
    color: #E8DCD0;
    padding: 2px 12px;
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; }

.css-9542yt-JoyLinearProgress-root::before {
  background-color: #E8DCD0 !important; }

.css-9542yt-JoyLinearProgress-root {
  background-color: #000 !important; }
`, "",{"version":3,"sources":["webpack://./src/components/ads.scss"],"names":[],"mappings":"AAAA;EACI,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,mBAAmB;EACnB,2EAA4E,EAAA;EALhF;IASQ,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,0BAA0B;IAC1B,mBAAmB,EAAA;EAd3B;IAkBQ,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB,EAAA;EAvB3B;IA2BQ,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;IAClB,eAAe,EAAA;EA9BvB;IAkCQ,cAAc;IACd,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB,EAAA;EAvC3B;IA2CQ,cAAc;IACd,iBAAiB;IACjB,cAAc;IACd,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB,EAAA;;AAI3B;EACI,oCAAoC,EAAA;;AAGxC;EACI,iCAAiC,EAAA","sourcesContent":[".ads {\n    width: 310px;\n    height: 161px;\n    margin: auto;\n    border-radius: 14px;\n    background: linear-gradient(180deg, rgba(21, 20, 26, 0.90) 0%, #2E2D33 100%);\n  \n\n    .running {\n        color: #E8DCD0;\n        font-size: 12px;\n        font-style: normal;\n        font-weight: 600;\n        padding: 16px 6px 6px 10px;\n        line-height: normal;\n    }\n\n    .number {\n        padding: 0px 6px;\n        color: #E8DCD0;\n        font-size: 40px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: normal;\n    }\n\n    .line {\n        stroke-width: 5px;\n        padding: 2px 12px;\n        border-radius: 6px;\n        stroke: #E8DCD0;\n    }\n\n    .share {\n        color: #E8DCD0;\n        padding: 2px 12px;\n        font-size: 16px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: normal;\n    }\n\n    .brand {\n        color: #E8DCD0;\n        padding: 2px 12px;\n        font-size: 9px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: normal;\n    }\n}\n\n.css-9542yt-JoyLinearProgress-root::before {\n    background-color: #E8DCD0 !important;\n}\n\n.css-9542yt-JoyLinearProgress-root {\n    background-color: #000 !important;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
