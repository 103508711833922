// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/locker.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.locker-container {
  border-radius: 10px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: 100%;
  /* Stretches only in the Y direction */
  display: flex;
  margin: 0px 12px;
  flex-direction: row-reverse;
  width: 90%;
  height: 200px;
  /* This can be set to auto to rely on aspect-ratio */
  justify-content: space-between;
  align-items: center; }
  .locker-container .lock-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around; }
  .locker-container .locker-name {
    color: #E8DCD0;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 11px;
    z-index: 2; }
  .locker-container .locker-box .locker {
    color: #E1C2C1;
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    margin-left: 10px;
    line-height: normal; }
`, "",{"version":3,"sources":["webpack://./src/components/locker.scss"],"names":[],"mappings":"AAAA;EACI,mBAAmB;EACnB,yDAA6C;EAC7C,4BAA4B;EAC5B,qBAAqB;EACrB,sCAAA;EACA,aAAa;EACb,gBAAgB;EAChB,2BAA2B;EAC3B,UAAU;EACV,aAAa;EACb,oDAAA;EACA,8BAA8B;EAC9B,mBAAmB,EAAA;EAbvB;IAgBQ,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,6BAA6B,EAAA;EApBrC;IAwBQ,cAAc;IAEd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,UAAU,EAAA;EA/BlB;IAoCY,cAAc;IAEd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB,EAAA","sourcesContent":[".locker-container {\n    border-radius: 10px;\n    background-image: url('../assets/locker.png');\n    background-repeat: no-repeat;\n    background-size: 100%;\n    /* Stretches only in the Y direction */\n    display: flex;\n    margin: 0px 12px;\n    flex-direction: row-reverse;\n    width: 90%;\n    height: 200px;\n    /* This can be set to auto to rely on aspect-ratio */\n    justify-content: space-between;\n    align-items: center;\n\n    .lock-content {\n        width: 100%;\n        height: 100%;\n        display: flex;\n        flex-direction: column;\n        justify-content: space-around;\n    }\n\n    .locker-name {\n        color: #E8DCD0;\n\n        font-size: 16px;\n        font-style: normal;\n        font-weight: 600;\n        line-height: normal;\n        margin-left: 11px;\n        z-index: 2;\n    }\n\n    .locker-box {\n        .locker {\n            color: #E1C2C1;\n\n            font-size: 42px;\n            font-style: normal;\n            font-weight: 400;\n            margin-left: 10px;\n            line-height: normal;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
