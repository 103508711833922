import React, { useState } from 'react';
import Ads from './components/Ads.js';
import Boxes from './components/Boxes.js';
import Device from './components/Device.js';
import FormData from './components/FormData.js';
import ItemClaim from './components/ItemClaim.js';
import Items from './components/Items.js';
import Locker from './components/Locker.js';
import Main from './components/Main.js';
import Map from "./components/Map.js";
import Menu from './components/Menu.js';
import Navbar from './components/Navbar.js';
import Profile from './components/Profile.js';
import Solar from './components/Solar.js';
import "./layout.scss";

const Layout = () => {
    const [selectedLocation, setSelectedLocation] = useState('');
    const [deviceName, setDeviceName] = useState(''); // Add state for selected device name
    const [devicelat, setDevicelat] = useState(''); // Add state for selected device name
    const [devicelng, setDevicelng] = useState(''); // Add state for selected device name

    return (
        <div className="parent">
            <div className="div1">
                <Navbar setSelectedLocation={setSelectedLocation} />
            </div>
            <div className="div2"><Profile /></div>
            <div className="div3"><Menu /></div>
            <div className="div4">
                <Map selectedLocation={selectedLocation} setDeviceName={setDeviceName} setDevicelat={setDevicelat} setDevicelng={setDevicelng} /> {/* Pass setDeviceName to Map */}
            </div>
            <div className="div5"><Main /></div>
            <div className="div6"><Boxes /></div>
            <div className="div7"><Ads /></div>
            <div className="div8"><Device deviceName={deviceName} devicelat={devicelat} devicelng={devicelng} /> {/* Pass the string deviceName to Device */}</div>
            <div className="div9"><Solar /></div>
            <div className="div10"><Locker /></div>
            <div className="div11"><ItemClaim /></div>
        </div>
    );
};

export default Layout;
