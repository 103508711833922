import React from 'react';
import './modal.scss'; // Ensure the stylesheet exists

function ImageModal({ isOpen, content, onClose }) {
    if (!isOpen || !content) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <span className="modal-close" onClick={onClose}>&times;</span>

                {content.contentType?.startsWith('video/') ? (
                    <video
                        className="modal-video"
                        src={content.url}
                        controls
                        autoPlay
                        style={{ width: '500px', height: '450px' }}
                    />
                ) : (
                    <img
                        className="modal-image"
                        src={content.url}
                        alt={content.name}
                        style={{ width: '450px', height: '450px' }}
                    />
                )}
            </div>
        </div>
    );
}

export default ImageModal;
