import React from 'react';
import "./solar.scss";

const Solar = () => {
    return (
        <div className='solar-container'>
            <div class="solar-header">
                Items in Box
            </div>
            <div class="solar-analysis">

                <div className="solcount-container">
                    <div className="solar-count">
                        0
                    </div>
                </div>
                {/* <div class="line">
                    <svg width="53" height="6" viewBox="0 0 53 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="58 " height="6" rx="3" fill="url(#paint0_linear_1175_6445)" />
                        <defs>
                            <linearGradient id="paint0_linear_1175_6445" x1="0" y1="3" x2="53" y2="3" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#E85D56" />
                                <stop offset="0.833381" stop-color="#AA3B36" />
                            </linearGradient>
                        </defs>
                    </svg>

                </div> */}
            </div>
        </div>
    )
}

export default Solar;
