import {
    deleteObject, getDownloadURL, getMetadata,
    getStorage, listAll, ref, uploadBytes
} from "firebase/storage";
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdImage from "../assets/ad-image.png";
import Line from "../assets/linedraw.png";
import Box from "../assets/smallbox.png";
import Upload from "../assets/upload.png";
import Button from "../components/Button.js";
import app from '../config/firebasecloud.js';
import "./adsbox.scss";

const Adsbox = ({ TabValue }) => {
    const [uploadedMedia, setUploadedMedia] = useState(null);
    const [mediaFile, setMediaFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [mediaURL, setMediaURL] = useState(null);
    const [currentDisplay, setCurrentDisplay] = useState(1);
    const [isMediaSelected, setIsMediaSelected] = useState(false);
    const [isVideo, setIsVideo] = useState(false);
    const fileInputRef = useRef(null);
    const [deleting, setDeleting] = useState(null);
    const [items, setItems] = useState([]);

    useEffect(() => {
        const fetchMediaDetails = async () => {
            try {
                const storage = getStorage(app);
                const listRef = ref(storage, `missingmatters_videos/116${TabValue}/`);
                const res = await listAll(listRef);
                const details = await Promise.all(res.items.map(async (itemRef) => {
                    const metadata = await getMetadata(itemRef);
                    return {
                        id: itemRef.fullPath,
                        name: metadata.name,
                        description: metadata.customMetadata?.description || 'No description',
                        size: metadata.size,
                        contentType: metadata.contentType,
                        updated: metadata.updated,
                        checked: false,
                    };
                }));
                setItems(details);
            } catch (err) {
                console.error("Error fetching media details:", err);
            }
        };
        fetchMediaDetails();
    }, [TabValue]);

    const handleMediaChange = (e) => {
        const media = e.target.files[0];
        if (media) {
            const isVideoFile = media.type.startsWith('video/');
            setIsVideo(isVideoFile);
            const reader = new FileReader();
            reader.onloadend = () => {
                setUploadedMedia(reader.result);
                setMediaFile(media);
                setIsMediaSelected(true);
            };
            reader.readAsDataURL(media);
        }
    };

    const handleUploadMedia = async () => {
        if (!uploadedMedia || !mediaFile) return;
        try {
            setUploading(true);
            const storage = getStorage(app);
            const storageRef = ref(storage, `${TabValue}/${mediaFile.name}`);
            const response = await fetch(uploadedMedia);
            const blob = await response.blob();
            await uploadBytes(storageRef, blob);
            const downloadURL = await getDownloadURL(storageRef);
            setMediaURL(downloadURL);

            toast.success('Media uploaded successfully!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "dark",
            });

            resetUpload();


        } catch (err) {
            console.error("Error uploading media:", err);
            toast.error('Failed to upload media!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "dark",
            });
        } finally {
            setUploading(false);
            setIsMediaSelected(false);
        }
    };

    const resetUpload = () => {
        setUploadedMedia(null);
        setMediaFile(null);
        setIsMediaSelected(false);
        setIsVideo(false);
        if (fileInputRef.current) fileInputRef.current.value = ''; // Reset file input
    };

    const handleCancel = () => {
        resetUpload(); // Reset everything on cancel
    };


    const handleDelete = async (filePath) => {
        try {
            setDeleting(filePath);
            const storage = getStorage(app);
            const fileRef = ref(storage, filePath);
            await deleteObject(fileRef);
            setItems(items.filter(item => item.id !== filePath));

            toast.success('Media deleted successfully!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "dark",
            });
        } catch (err) {
            console.error("Error deleting media:", err);
            toast.error('Failed to delete media!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "dark",
            });
        } finally {
            setDeleting(null);
        }
    };

    const handleDisplayChange = (direction) => {
        if (direction === 'left' && currentDisplay > 1) {
            setCurrentDisplay(currentDisplay - 1);
        } else if (direction === 'right' && currentDisplay < 3) {
            setCurrentDisplay(currentDisplay + 1);
        }
    };

    return (
        <div className='adsbox'>
            <ToastContainer />
            <div className="heading">
                <div className="box">
                    <img src={Box} width={87} height={70} alt="box" />
                </div>
                <div className="boxname">
                    MM - 116{TabValue}
                    <br />
                    <div className="address">
                    </div>
                </div>
            </div>

            <div className="navigation">
                <AiOutlineLeft
                    onClick={() => handleDisplayChange('left')}
                    className={`arrow ${currentDisplay <= 1 ? 'disabled' : ''}`}
                />
                <div className="boxname">Display - {currentDisplay}</div>
                <AiOutlineRight
                    onClick={() => handleDisplayChange('right')}
                    className={`arrow ${currentDisplay >= 3 ? 'disabled' : ''}`}
                />
            </div>
            <img src={Line} alt="line" />

            <div className="img-container">
                {uploading ? (
                    <div className="spin">
                        <div className="spinner"></div>
                    </div>
                ) : isVideo ? (
                    <video
                        src={uploadedMedia ? uploadedMedia : AdImage}
                        controls
                        className="ad-media"
                        style={{ width: "200px", height: "150px" }}
                    />
                ) : (
                    <img
                        src={uploadedMedia ? uploadedMedia : AdImage}
                        alt="ad"
                        className="ad-image"
                        style={{ width: "200px", height: "150px" }}
                    />
                )}
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleMediaChange}
                    accept="image/*,video/*"
                />
                {!isMediaSelected && !uploading && (
                    <div className="overlay" onClick={() => fileInputRef.current.click()}>
                        <img src={Upload} alt="upload" />
                    </div>
                )}
            </div>
            <img src={Line} alt="line" />

            {isMediaSelected && (
                <div className="button-info">
                    <Button content={"Upload"} onClick={handleUploadMedia} />
                    <MdDeleteOutline className="upload-icon" onClick={handleCancel} size={24} />
                </div>
            )}
            <h4 className="header">Sequence</h4>

            <ul className="listbox">
                {items.map((item, index) => (
                    <li key={item.id} className="list">
                        <div className="count">
                            <div className="number">{index + 1}</div>
                        </div>
                        <div className="content">
                            <div className="adname">
                                {item.name.length > 15 ? `${item.name.substring(0, 20)}..` : item.name}
                            </div>
                            <div className="addes">{item.contentType}</div>
                        </div>
                        <MdDeleteOutline
                            className="delete-icon"
                            onClick={() => handleDelete(item.id)}
                            disabled={deleting === item.id}
                        />
                        {deleting === item.id && <span className="loading-text">...</span>}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Adsbox;

