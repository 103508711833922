// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile {
  display: flex;
  height: 66px;
  padding: 0px 24px 5px 5px;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  background: #100F15;
  margin-top: 5px;
  margin-left: 5px; }
  .profile .profile-img {
    width: 46px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 44px; }
  .profile .box {
    width: 100%; }
  .profile .name {
    color: #D9D9D9;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; }
  .profile .role {
    color: #E85D56;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; }
  .profile .logout {
    cursor: pointer;
    font-size: 15px; }
    .profile .logout button {
      width: 60px;
      padding: 7px;
      background: linear-gradient(180deg, #CD4D47 0%, #9A3A35 39.25%, #672724 86.5%);
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer; }
`, "",{"version":3,"sources":["webpack://./src/components/profile.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,YAAY;EACZ,yBAAyB;EACzB,uBAAuB;EACvB,SAAS;EACT,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,gBAAgB,EAAA;EATpB;IAYQ,WAAW;IACX,YAAY;IACZ,cAAc;IACd,mBAAmB,EAAA;EAf3B;IAmBQ,WAAW,EAAA;EAnBnB;IAuBQ,cAAc;IAEd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB,EAAA;EA5B3B;IAgCQ,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB,EAAA;EApC3B;IAwCQ,eAAe;IACf,eAAe,EAAA;IAzCvB;MA2CgB,WAAW;MACX,YAAY;MACZ,8EAA8E;MAC9E,YAAY;MACZ,YAAY;MACZ,kBAAkB;MAClB,eAAe,EAAA","sourcesContent":[".profile {\n    display: flex;\n    height: 66px;\n    padding: 0px 24px 5px 5px;\n    align-items: flex-start;\n    gap: 10px;\n    flex-shrink: 0;\n    background: #100F15;\n    margin-top: 5px;\n    margin-left: 5px;\n\n    .profile-img {\n        width: 46px;\n        height: 48px;\n        flex-shrink: 0;\n        border-radius: 44px;\n    }\n\n    .box {\n        width: 100%;\n    }\n\n    .name {\n        color: #D9D9D9;\n\n        font-size: 15px;\n        font-style: normal;\n        font-weight: 600;\n        line-height: normal;\n    }\n\n    .role {\n        color: #E85D56;\n        font-size: 14px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: normal;\n    }\n\n    .logout {\n        cursor: pointer;\n        font-size: 15px;\n        button {\n                width: 60px;\n                padding: 7px;\n                background: linear-gradient(180deg, #CD4D47 0%, #9A3A35 39.25%, #672724 86.5%);\n                color: white;\n                border: none;\n                border-radius: 4px;\n                cursor: pointer;\n            }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
