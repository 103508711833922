// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
  flex-direction: column;
  /* Prevent scrolling on the modal */ }

.modal-content {
  position: relative;
  background: #14141496;
  padding: 0;
  /* Remove padding to allow the image to take full space */
  border-radius: 8px;
  width: 400px;
  height: 400px;
  /* Prevent scrolling inside the modal */
  display: flex;
  /* Center content */
  justify-content: center;
  align-items: center; }

.modal-image {
  width: 400px;
  /* Ensure the image scales proportionally */
  height: 400px;
  /* Scale the image by 2x */
  transform-origin: center;
  /* Keep the image centered while scaling */
  object-fit: contain;
  pointer-events: none; }

.modal-close {
  position: absolute;
  top: -28px;
  right: -16px;
  color: rgba(245, 245, 220, 0.973);
  font-size: 24px;
  cursor: pointer;
  z-index: 1000;
  /* Ensure the close button is above the image */ }
`, "",{"version":3,"sources":["webpack://./src/components/modal.scss"],"names":[],"mappings":"AAAA;EACI,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,gBAAgB;EAChB,sBAAsB;EACtB,mCAAA,EAAoC;;AAGxC;EACI,kBAAkB;EAClB,qBAAqB;EACrB,UAAU;EACV,yDAAA;EACA,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,uCAAA;EACA,aAAa;EACb,mBAAA;EACA,uBAAuB;EACvB,mBAAmB,EAAA;;AAGvB;EACI,YAAY;EACZ,2CAAA;EACA,aAAa;EACb,0BAAA;EACA,wBAAwB;EACxB,0CAAA;EACA,mBAAmB;EACnB,oBAAoB,EAAA;;AAGxB;EACI,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,iCAAiC;EACjC,eAAe;EACf,eAAe;EACf,aAAa;EACb,+CAAA,EAAgD","sourcesContent":[".modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.7);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1000;\n    overflow: hidden;\n    flex-direction: column;\n    /* Prevent scrolling on the modal */\n}\n\n.modal-content {\n    position: relative;\n    background: #14141496;\n    padding: 0;\n    /* Remove padding to allow the image to take full space */\n    border-radius: 8px;\n    width: 400px;\n    height: 400px;\n    /* Prevent scrolling inside the modal */\n    display: flex;\n    /* Center content */\n    justify-content: center;\n    align-items: center;\n}\n\n.modal-image {\n    width: 400px;\n    /* Ensure the image scales proportionally */\n    height: 400px;\n    /* Scale the image by 2x */\n    transform-origin: center;\n    /* Keep the image centered while scaling */\n    object-fit: contain;\n    pointer-events: none;\n}\n\n.modal-close {\n    position: absolute;\n    top: -28px;\n    right: -16px;\n    color: rgba(245, 245, 220, 0.973);\n    font-size: 24px;\n    cursor: pointer;\n    z-index: 1000;\n    /* Ensure the close button is above the image */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
