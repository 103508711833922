import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth'; // Import signOut method from Firebase
import { auth } from '../config/firebasecloud.js'; // Import the auth instance
import Image from "../assets/missingmatters.png";
import "./profile.scss";

const Profile = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  // Retrieve email from Firebase Auth or set a default email
  useEffect(() => {
    const storedEmail = 'MM ADMIN'; // Use Firebase to get the authenticated user's email
    setEmail(storedEmail);
  }, []);

  const role = localStorage.getItem('role') || 'ADMIN'; // Keep role as local storage if needed

  // Handle Firebase logout
  const handleLogout = async () => {
    try {
      await signOut(auth); // Firebase logout
      localStorage.clear(); // Optional: Clear any other local storage data
      navigate('/'); // Navigate back to login page
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <div className='profile'>
      <img className='profile-img' src={Image} alt="Profile" />
      <div className="box">
        <div className="name">{email}</div>
        <div className="role">{role}</div>
      </div>
      <div className="name logout">
        <button onClick={handleLogout}>Logout</button>
      </div>
    </div>
  );
}

export default Profile;
