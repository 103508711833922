import React, { useEffect, useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth'; // Import Firebase auth methods
import { auth } from './config/firebasecloud.js'; // Import Firebase config
import './App.scss';
import Login from './components/Login';
import Display from './Display';
import Layout from './Layout';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true); // To track whether we are checking login status

  // Check Firebase Authentication status on component mount
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
      setLoading(false); // Once the check is done, stop loading
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Handle logout functionality
  const handleLogout = async () => {
    try {
      await signOut(auth); // Sign out from Firebase
      setIsLoggedIn(false); // Update local state
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Show a loading screen while checking auth status
  }

  return (
    <Router>
      <div>
        <Routes>
          {/* Public route for login */}
          <Route
            path="/"
            element={<Login onLogin={() => setIsLoggedIn(true)} />}
          />

          {/* Protected routes for logged-in users */}
          {isLoggedIn ? (
            <>
              <Route path="/display" element={<Display />} />
              <Route path="/home" element={<Layout onLogout={handleLogout} />} />
              <Route path="*" element={<Navigate to="/home" />} />
            </>
          ) : (
            <Route path="*" element={<Navigate to="/" />} />
          )}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
