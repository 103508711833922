// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  width: 315px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 161px;
  flex-shrink: 0;
  border-radius: 18px;
  background: linear-gradient(180deg, rgba(21, 20, 26, 0.9) 0%, #2E2D33 100%); }
  .main .top .heading {
    margin: 14px;
    color: #E8DCD0;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; }
  .main .bottom {
    margin-bottom: 20px;
    margin-left: 10px; }
    .main .bottom .graph-box {
      display: flex;
      flex-direction: row; }
      .main .bottom .graph-box .number {
        color: #E8DCD0;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; }
`, "",{"version":3,"sources":["webpack://./src/components/main.scss"],"names":[],"mappings":"AAAA;EACI,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,aAAa;EACb,cAAc;EACd,mBAAmB;EACnB,2EAA4E,EAAA;EAThF;IAaY,YAAY;IACZ,cAAc;IAEd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB,EAAA;EAnB/B;IA6CQ,mBAAmB;IACnB,iBAAiB,EAAA;IA9CzB;MA0BY,aAAa;MACb,mBAAmB,EAAA;MA3B/B;QA8BgB,cAAc;QAEd,eAAe;QACf,kBAAkB;QAClB,gBAAgB;QAChB,mBAAmB,EAAA","sourcesContent":[".main {\n    width: 315px;\n    margin: auto;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    height: 161px;\n    flex-shrink: 0;\n    border-radius: 18px;\n    background: linear-gradient(180deg, rgba(21, 20, 26, 0.90) 0%, #2E2D33 100%);\n\n    .top {\n        .heading {\n            margin: 14px;\n            color: #E8DCD0;\n\n            font-size: 18px;\n            font-style: normal;\n            font-weight: 400;\n            line-height: normal;\n\n        }\n    }\n\n    .bottom {\n        .graph-box {\n            display: flex;\n            flex-direction: row;\n\n            .number {\n                color: #E8DCD0;\n\n                font-size: 32px;\n                font-style: normal;\n                font-weight: 400;\n                line-height: normal;\n            }\n\n            // .graph {\n            //     position: relative;\n            //     bottom: 60px;\n            // }\n        }\n\n\n        margin-bottom: 20px;\n        margin-left: 10px;\n    }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
