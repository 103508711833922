import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Dev from "../assets/box.png";
import Locations from "../assets/location.png";
import Button from "./Button.js";
import "./device.scss";

const Device = ({ deviceName, devicelat, devicelng }) => {
    const [address, setAddress] = useState('');
    const navigate = useNavigate();

    // Function to fetch address from lat and lng using Geocoding API
    const fetchAddress = async (lat, lng) => {
        const apiKey = "AIzaSyDawx-PwOvSV24Rkk4WPvvwe79gDDmWIyI"; // Use your Google Maps API key here
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

        try {
            const response = await fetch(url);
            const data = await response.json();
            if (data.results && data.results.length > 0) {
                // Get the full formatted address
                let fullAddress = data.results[0].formatted_address;

                // List of cities to trim at
                const cities = ['Hyderabad', 'Bengaluru'];

                // Find the first city in the address and trim the remaining part
                let trimmedAddress = fullAddress;
                cities.forEach(city => {
                    if (fullAddress.includes(city)) {
                        const cutoffIndex = fullAddress.indexOf(city) + city.length;
                        trimmedAddress = fullAddress.slice(0, cutoffIndex).trim();
                    }
                });

                // Split the address if it contains parts like "Shilpa Valley"
                let addressParts = trimmedAddress.split(', ');
                let firstPart = addressParts.slice(0, 2).join(', ');  // Up to "Shilpa Valley"
                let secondPart = addressParts.slice(2, 3).join(', ');   // After "Shilpa Valley"
                let thirdPart = addressParts.slice(3).join(', ');   // After "Shilpa Valley"

                // Set the final address parts
                setAddress({ firstPart, secondPart, thirdPart });
            } else {
                setAddress({ firstPart: "Unknown Location", secondPart: "" });
            }
        } catch (error) {
            console.error("Error fetching address:", error);
            setAddress({ firstPart: "Error fetching location", secondPart: "" });
        }
    };

    // Fetch address when lat and lng change
    useEffect(() => {
        if (devicelat && devicelng) {
            fetchAddress(devicelat, devicelng);
        }
    }, [devicelat, devicelng]);

    return (
        <div className='device-bg'>
            <div className="container">
                <div className="device">
                    <img src={Dev} height={180} alt="Device" />
                </div>
                <div className="content">
                    <div className="device-id">
                        {deviceName ? (
                            <>
                                {deviceName}
                            </>
                        ) : (
                            <>...</>
                        )}
                    </div>
                    <div className="address">
                        <div className="location">
                            <img src={Locations} alt="" />
                        </div>
                        <div className="address-cont">
                            {/* Display the fetched address */}
                            {address.firstPart ? (
                                <>
                                    {address.firstPart} <br />
                                    {address.secondPart}
                                    <br />
                                    {address.thirdPart}
                                </>
                            ) : (
                                <>...</>
                            )}
                        </div>
                    </div>

                    <div className="grid-container">
                        <div className="grid-item">
                            <div className="grid-number"> OFF</div>
                            <div className="grid-content">Display</div>
                        </div>
                        <div className="grid-item">
                            <div className="grid-number"> 0</div>
                            <div className="grid-content">Items</div>
                        </div>
                        <div className="grid-item">
                            <div className="grid-number"> 56%</div>
                            <div className="grid-content">Status</div>
                        </div>
                    </div>
                    <div className="button-info">
                        <Link
                            to={{
                                pathname: "/display",
                                state: {
                                    deviceName: deviceName || "Unknown Device",  // Fallback to avoid undefined
                                    address: address || { firstPart: "Unknown Location", secondPart: "", thirdPart: "" }  // Fallback
                                }
                            }}
                        >
                            <Button content={"Device Info"} />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Device;
