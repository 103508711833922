import { getDownloadURL, getMetadata, getStorage, listAll, ref } from "firebase/storage";
import React, { useEffect, useState } from 'react';
import Ai from "../assets/ai.png";
import Alert from "../assets/alert.png";
import Found from "../assets/found.png";
import GalleryIcon from "../assets/galleryicon.png";
import app from '../config/firebasecloud.js';
import VideoModal from './VideoModal'; // Import updated modal for videos
import ImageModal from "./Modal.js";
import './tab.scss';

function Tabbar({ activeTopTab, setActiveTopTab }) {
    const [items, setItems] = useState([]);
    const [pictures,setPictures] = useState([]);
    const [modalOpen, setModalOpen] = useState(false); // State to control modal visibility
    const [modalContent, setModalContent] = useState(null); // State to hold video or image content

    useEffect(() => {
        const fetchMediaPictureDetail = async () => {
            try {
                const storage = getStorage(app);
                const listRef = ref(storage, `missingmatters_photos/116${activeTopTab}/`); // Updated path

                const res = await listAll(listRef);
                const details = await Promise.all(res.items.map(async (itemRef) => {
                    const metadata = await getMetadata(itemRef);
                    const url = await getDownloadURL(itemRef);

                    return {
                        id: itemRef.fullPath,
                        name: metadata.name,
                        description: metadata.customMetadata?.description || 'No description',
                        size: metadata.size,
                        contentType: metadata.contentType,
                        updated: metadata.updated,
                        url: url,
                    };
                }));

                setPictures(details);
            } catch (err) {
                console.error("Error fetching media details:", err);
            }
        };

        fetchMediaPictureDetail();

        const fetchMediaDetails = async () => {
            try {
                const storage = getStorage(app);
                const listRef = ref(storage, `missingmatters_videos/116${activeTopTab}/`);

                const res = await listAll(listRef);
                const details = await Promise.all(res.items.map(async (itemRef) => {
                    const metadata = await getMetadata(itemRef);
                    const url = await getDownloadURL(itemRef);

                    return {
                        id: itemRef.fullPath,
                        name: metadata.name,
                        description: metadata.customMetadata?.description || 'No description',
                        size: metadata.size,
                        contentType: metadata.contentType,
                        updated: metadata.updated,
                        url: url,
                    };
                }));

                setItems(details);
            } catch (err) {
                console.error("Error fetching media details:", err);
            }
        };

        fetchMediaDetails();
    }, [activeTopTab]);

    const handleTabClick = (e, tabIndex) => {
        e.preventDefault();
        setActiveTopTab(tabIndex);
    };

    const openModal = (content) => {
        setModalContent(content);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalContent(null);
    };

    return (
        <div className="tabs">
            <ul className="tabs-nav">
                {[1, 2, 3].map((tabIndex) => (
                    <li
                        key={`top-tab-${tabIndex}`}
                        className={activeTopTab === tabIndex ? 'tab-active' : ''}
                    >
                        <a href="/" onClick={(e) => handleTabClick(e, tabIndex)}>
                            MM - 116{tabIndex}
                        </a>
                    </li>
                ))}
            </ul>
            <div className="tabs-stage">
                <div className="tab-content active">
                    <div className="cards">
                        {items.map((item) => (
                            <div className='card' key={item.id}>
                                <div className='card-container'>
                                    {item.contentType.startsWith('video/') ? (
                                        <video
                                            className='card-image'
                                            width={160}
                                            height={120}
                                            src={item.url}
                                            onClick={() => openModal(item)}
                                            controls
                                        />
                                    ) : (
                                        <img
                                            className='card-image'
                                            width={160}
                                            height={120}
                                            src={item.url}
                                            alt={`Content for ${item.name}`}
                                            onClick={() => openModal(item)}
                                        />
                                    )}
                                    <div className="overlay" onClick={() => openModal(item)}>
                                        <img src={GalleryIcon} alt="Gallery icon" />
                                    </div>
                                </div>
                                <p className='card-content'>{item.name}</p>
                            </div>
                        ))}
                    </div>
                    <div className="table_component" role="region" tabIndex="0">
                        <table>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Image</th>
                                    <th>Chatbot Image</th>
                                    <th>Add. Details</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pictures.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <div className="table-row">
                                                <img
                                                    src={item.url}
                                                    onClick={() => openModal(item)} // Open modal on image click
                                                />
                                                <div className="table-data">
                                                    <div className="table-head">{item.name}</div>
                                                </div>
                                            </div>
                                        </td>
                                        
                                        <td>
                                            <div className="table-row">
                                                <img
                                                    src={item.url}
                                                    onClick={() => openModal(item)} // Open modal on image click
                                                />
                                                <div className="table-data">
                                                    <div className="table-head">{item.name}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="table-row">
                                                <div className="table-data">
                                                    <div className="table-head">Size: {item.size} bytes</div>
                                                    <div className="table-text">{new Date(item.updated).toLocaleDateString()}</div>
                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            <div className="status">
                                                <div className="status-text">Claimed</div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <ImageModal
                isOpen={modalOpen}
                content={modalContent}  // Correctly pass modalContent instead of modalImageSrc
                onClose={closeModal}
            />


        </div>
    );
}

export default Tabbar;
