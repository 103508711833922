// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
    apiKey: "AIzaSyDTuoNvqSAR2aSJoMOffC0fPEY1E1t6t9g",
    authDomain: "missingmatters-web-app.firebaseapp.com",
    databaseURL: "https://missingmatters-web-app-default-rtdb.firebaseio.com",
    projectId: "missingmatters-web-app",
    storageBucket: "missingmatters-web-app.appspot.com",
    messagingSenderId: "719561053420",
    appId: "1:719561053420:web:9db47634bf19074fbc41c9",
    measurementId: "G-5HCRHPJRTN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Export the authentication instance
export const auth = getAuth(app);

export default app;
