import React from 'react';
import './modal.scss'; // Style for the modal

function VideoModal({ isOpen, content, onClose }) {
    if (!isOpen || !content) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                {content.contentType.startsWith('video/') ? (
                    <video
                        src={content.url}
                        controls
                        autoPlay
                        className="modal-video"
                    />
                ) : (
                    <img
                        src={content.url}
                        alt={content.name}
                        className="modal-image"
                    />
                )}
                <button className="close-button" onClick={onClose}>
                    Close
                </button>
            </div>
        </div>
    );
}

export default VideoModal;
